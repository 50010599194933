




/* ------------------------------------------------ *\
 * X. Panel
\* ------------------------------------------------ */

.popup__mask {
	position: fixed; 
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/* background: rgba(0, 0, 0, 0.75); */
	background: white;
	z-index: 500;
}


.popup {
	background: white;
	width: 75%;
	max-width: 1024px;
	min-width: 300px;
	margin: 2.5em auto 5em auto;
	max-height: 650px;
	height: calc(100% - 8em);
}






.popup__frame {
	width: 100%;
	height: 100%; 
	border: none;
}

.popup__content {
	width: 100%;
	height: 100%;
}

.btn-popup-close { 
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 1em;
	font-size: smaller;
	text-decoration: none;
}







/* ------------------------------------------------ *\
 * X. Viewpanel
\* ------------------------------------------------ */

.viewpanel {
	visibility: hidden;
	position: fixed;
	z-index: 1000;
	top: 0;
	right: -720px;
	width: 720px;
	max-width: 100%;
	height: 100vh;
	transition: .25s all;
	box-shadow: 0 0 10px rgba(0,0,0,.25);
	background: #eee
  }
  .viewpanel.open {
	visibility:visible;
	right:0
  }
  .viewpanel.open::before {
	position:absolute;
	content:'';
	background:rgba(255,255,255,.1);
	width:100%;
	right:0;
	top:0;
	height:100%;
	z-index:-10;
	transition:.5s all
  }
  .viewpanel.open::before {
	background:rgba(255,255,255,.85)
  }
  .viewpanel__heading {
	display:flex;
	align-items:center;
	background-color:#eee
  }
  .viewpanel .card-header::after {
	content:none
  }
  .viewpanel__heading__title {
	flex-grow:1;
	flex-shrink:1;
	margin-bottom:1em;
	padding:0 15px;
	margin:0;
	height:50px;
	line-height:50px;
	margin:0
  }
  .ghost-title .viewpanel__heading__close-link {
	padding:0 .75em;
	color:#245580
  }
  .viewpanel__content {
	height:calc(100% - 50px);
	overflow-y:auto;
	padding:15px
  }
  .viewpanel legend {
	background:#666;
	color:#eee;
	font-size:13px;
	line-height:1em;
	text-transform:uppercase;
	font-weight:400;
	letter-spacing:.25px;
	box-shadow:none
  }
  @media screen and (max-width:959px) {
	.viewpanel {
	  width:100%;
	  min-width:0
	}
  }

