
/* ------------------------------------------------ *\
 * X. Footer
\* ------------------------------------------------ */

footer {
	background: #000;
	color: #bbb; 
}

	footer a {
		color: #eee;
		font-weight: 400;
	}

	footer a:hover {
		color: #fff;
	}









/* ------------------------------------------------ *\
 * 1. ....
\* ------------------------------------------------ */

/*
html, 
body {
	height: 100%;
	position: relative;
}

.page {
  min-height: calc(100% - 100px);
}

footer {
	position: absolute;
	bottom: 0;
	height: 100px;
	width: 100%;
	// font-size: 11px;
}

@media screen and (min-width: 767px){ }

@media screen and (min-width: 959px){ }

@media screen and (min-width: 1279px), print { }
*/




