


fieldset legend {
    background:#f6f6f6;
    padding: 0.75em 1em 0.6em 1em;
    border-bottom:none;
    box-shadow:inset 0 -5px 10px #f0f0f0 
}

.viewpanel legend {
    background:#666;
    color:#eee;
    font-size:13px;
    line-height:1em;
    text-transform:uppercase;
    font-weight:400;
    letter-spacing:.25px;
    -webkit-box-shadow:none;
    box-shadow:none
  }