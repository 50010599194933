
body {
    // background: #333;
    // color: #eee;
}



@keyframes dotLoader {
    0% { transform: translateX(0rem) }
    70% { transform: translateX(1rem) }
    100% { transform: translateX(1rem) }
}

@keyframes dotLoaderReverse {
    0% { transform: translateX(0rem) }
    70% { transform: translateX(-1rem) }
    100% { transform: translateX(-1rem) }
}


.bouncing-loader {

    display: block;
    position: relative;

    // background: #333;
    color: #999;

    &__container {
        position: relative;
        // display: flex;
        // justify-content: space-between;
        margin: 0 auto;
        padding: 0.5rem 1rem;
        text-align: center;
        max-width: 3rem;
    }

    &__dot {
        width: 0.45rem;
        height: 0.45rem;
        border-radius: 3px;
        opacity: 0.8;
        background: #ccc;
    }

    &__dot--1, 
    &__dot--2 {
        position: absolute;
        top: auto;
        left: auto;
    }

    &__dot--1{
        animation: 0.7s ease-in-out infinite alternate dotLoader;
    }

    &__dot--3{
        animation: 1s ease-in-out infinite alternate dotLoaderReverse;
    }

}

