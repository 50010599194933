



.text-align-left,
.tal,
.tal.control-label {
	text-align: left;
}

.text-align-right,
.tar,
.tar.control-label {
	text-align: right;
}

.text-align-center,
.tac,
.tac.control-label {
	text-align: center;
}



.fl,
.float,
.float--left { float: left; }

.fr,
.float--right { float: right; }



