
@mixin reset {
    margin: 0;
    padding: 0;
    list-style: none;
}

@mixin inherit-font {
    font-size: inherit !important;
    line-height: inherit !important;
}

.font-inherit {
    @include inherit-font;
}
