
header {
	background: #333;
	color: #eee; 
}


.logo {
	font-family: 'Passion One';
	display: block; 
	float: left;
	position: relative;
	font-size: 38px;
	font-size: 33px;
	font-weight: normal;
	line-height: 1em;
	color: #fff;

	padding: 0 20px 8px 5px;
	margin: 0 30px 0 0;
}

	.logo .subtitle {
		position: absolute;
		display: block;
		font-size: 14px;
		font-size: 12px;		
		line-height: 1em;
		color: #aaa;
		margin: 0;
		padding: 0;

		top: 2.2em;
		// left: 20px;		
		left: 30px;		
	}


@media all and (min-width: 767px){
	
	.logo {
		// padding: 0;
		// margin: 0 30px 0 0;
	}
	
	.logo .subtitle {
		// top: 2.2em;
		// left: 20px;
	}

	.navbar-toggle { 
	  margin-right: 0;
	}	

	.container-fluid > .navbar-header, 
	.container > .navbar-header {
	    margin-right: 0;  
	    margin-left: 0;
	}
	
}

@media all and (min-width: 959px){
	
	.logo {
		padding: 0 20px 8px 5px;
		margin: 0 30px 0 0;
	}
	
	.logo .subtitle {
		top: 2.2em;
		// left: 25px;
		left: 30px;
	}
	
}

@media all and (min-width: 1279px){
	
	.logo {
		padding: 0 35px 8px 10px;
		margin: 0 30px 0 0;
	}
		
		.logo .subtitle {
			top: 2.2em;
			left: 30px;
		}
	
}
