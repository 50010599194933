
/* ------------------------------------------------ *\
 * base--fonts.css
\* ------------------------------------------------ */

body,
.body,
.body-reset {
	font-size: 12px;
	line-height: 1.5em;
}

h1, .h1 { font-size: 2.5rem; line-height: 3.57rem; }

h2, .h2 { font-size: 2.29rem; }

h3, .h3 { font-size: 1.86rem; }

h4, .h4 { font-size: 1.5rem; }

h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
	line-height: 1.25em;
}

.smaller,
.content--smaller {

	font-size: 0.75rem;
	line-height: 1.5em;

	h1, .h1 { font-size: 2.5rem; line-height: 3.57rem; }

	h2, .h2 { font-size: 1.5rem; }

	h3, .h3 { font-size: 1.25rem; }

	h4, .h4 { font-size: 1rem; }

	h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
		line-height: 1.5em;
	}

}

.small-caps,
.tiny {
	font-size: 13px;
	line-height: 1.5em;
}



@media screen and (min-width: 767px){

}

@media screen and (min-width: 959px){

	body,
	.body,
	.body-reset {
		font-size: 14px;
		line-height: 1.5em;
	}

	h1, .h1 { font-size: 2.5rem; line-height: 3.57rem; }

	h2, .h2 { font-size: 2.29rem; }

	h3, .h3 { font-size: 1.86rem; }

	h4, .h4 { font-size: 1.5rem; }

	h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
		line-height: 1.25em;
	}

	.smaller,
	.content--smaller {

		font-size: 0.75rem;
		line-height: 1.5em;

		h1, .h1 { font-size: 2.5rem; line-height: 3.57rem; }

		h2, .h2 { font-size: 1.5rem; }

		h3, .h3 { font-size: 1.25rem; }

		h4, .h4 { font-size: 1rem; }

		h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
			line-height: 1.5em;
		}

	}

	.small-caps,
	.tiny {
		font-size: 13px;
		line-height: 1.5em;
	}

}

@media screen and (min-width: 1279px), print {
	
	body,
	.body,
	.body-reset {
		font-size: 16px;
		line-height: 1.5em;
	}

	h1, .h1 { font-size: 2.5rem; line-height: 3.57rem; }

	h2, .h2 { font-size: 2.29rem; }

	h3, .h3 { font-size: 1.86rem; }

	h4, .h4 { font-size: 1.5rem; }

	h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
		line-height: 1.25em;
	}

	.smaller,
	.content--smaller {

		font-size: 14px;
		line-height: 1.5em;

		h1, .h1 { font-size: 2.5rem; line-height: 3.57rem; }

		h2, .h2 { font-size: 1.5rem; }

		h3, .h3 { font-size: 1.25rem; }

		h4, .h4 { font-size: 1rem; }

		h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
			line-height: 1.5em;
		}

	}

	.small-caps,
	.tiny,
	.content--tiny {

		font-size: 12px;
		line-height: 1.5em;

		h1, .h1 { font-size: 2.5rem; line-height: 3.57rem; }

		h2, .h2 { font-size: 1.5rem; }

		h3, .h3 { font-size: 1.25rem; }
 
		h4, .h4 { font-size: 1rem; }

		h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
			line-height: 1.5em;
		}

	}

}




.body-reset {
	margin: 0;
	padding: 0;
}






// small, 
// .small {
// 	font-size: 85%;
// 	line-height: inherit;
// }
 
// .smaller {
// 	font-size: 50%;
// 	line-height: inherit; 
// }

.small-caps,
.uppercase {
	text-transform: uppercase;
	/* Letterspace compensation: */
	letter-spacing: 0.25px;
}

	.small.uppercase {
		letter-spacing: 0.375px;
	}

	.smaller.uppercase {
		letter-spacing: 0.5px;
	}







.sentencecase {
	text-transform: none;
	letter-spacing: 0;
}
	.small.sentencecase {
		letter-spacing: 0.125px;
	}

	.smaller.sentencecase {
		letter-spacing: 0.25px;
	}





.light { font-weight: 300 }

.normal { font-weight: 400 }

.strong { font-weight: 500 }

.heavy { font-weight: 700 }
