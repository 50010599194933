
/* ------------------------------------------------ *\
 * layout--banners.css
\* ------------------------------------------------ */

.region--banner {
	
}

.banner {
	background: #eee;
	color: #111;
}

.banner__title {
	color: #444;
}

.banner__subtitle {
	color: #777;
}

.banner__strapline {
  
}

.banner__text {

}


	
	.banner {
		// padding-top: 2em;
		// padding-bottom: 1em;
	}
	
	.banner__title {
		font-family: 'Passion One', serif;
		font-weight: 400;
		font-size: 64px;
		line-height: 1em;
		position: relative;
		display: inline-block;
		padding: 0 0 2% 0;
		// margin: 0.25em auto 0.5em auto;
		margin: 3rem auto 3rem auto;

	}

	.banner__subtitle {
		position: absolute;
		bottom: 0;
		right: 0;
		display: block;
		font-size: 35%;
		line-height: 1em;
		text-indent: 5px;
	}
	
	.banner__strapline {
		font-size: 1.4em;
		line-height: 1.4em;
	}

	.banner__text {
		font-size: 1.2em;
		line-height: 1.4em;
	}	
	


	

@media screen and (min-width: 767px){
	
	.banner {
		// padding-bottom: 2.5em;
		text-align: center;
	}
	
	.banner__strapline {
		padding: 0; 
		margin: 0 0 1em 0;
	}
	
	.banner__text {
		padding: 0; 
		margin: 0 0 2em 0;
	}

	.banner__title {
		font-family: 'Passion One', serif;
		font-weight: 400;
		// font-size: 90px;
		font-size: 63px;
		line-height: 1em;
		position: relative;
		display: inline-block;
		// padding: 0 0 1.25% 0;
		padding: 0 0 1.0% 0;
		margin: 0 auto;
	}

		.banner__subtitle {
			position: absolute;
			bottom: 0;
			right: 0;
			display: block;
			font-size: 35%;
			line-height: 1em;
			text-indent: 5px;
		}
	
	.banner__strapline {
		font-size: 1.7em;
		line-height: 1.4em;
	}

	.banner__text {
		font-size: 1.3em;
		line-height: 1.4em;
	}		
	
}


