
:root {
    
    --color-primary: #245580;
    --color-primary-highlighted: #043550;
    
    --color-secondary: #ddd;
    --color-secondary-highlighted: #ccc;
    
    --color-good: #090;
    --color-good-highlight: #090;  
    
    --color-good: #2A9D8F;
    --color-good-highlight: #2A9D8F;  
    
    --color-green: #2A9D8F;
    --color-green-highlight: #2A9D8F;
    
    --color-blue: #264653;
    --color-blue-highlight: #264653;
    
    --color-purple: #bf9aca;
    --color-purple-highlight: #8e4162;
    
    --color-white: #ffffff;
    
    --color-black: #222;
    
    --color-warning: #B9314F;
    
    --color-red: #B9314F;

}


