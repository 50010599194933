
/* ------------------------------------------------ *\
 * tasks.css
\* ------------------------------------------------ */


.task {
  position: relative;
}


  .task__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0.5;
    background: #fff;
  }

  .js-loading::after {
    content: 'Saving...';
    
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;

    display: flex;
    justify-content: center;
    align-items: center;
    
    opacity: 0.75;
    background: #fff;
    color: #999;
    font-weight: 500;
    color: small;
  }




/*
.task__wrap * {
  display: block;
  padding: 0;
  margin: 0;
}
*/

.widget-list * {
  z-index: initial;
  overflow: visible;  
}

.task__wrap {
  /*
  padding-left: 50px;
  padding-right: 55px;
  */
}

.task__wrap {
  // position: relative;

  padding-left: 0;
  padding-right: 0;

  display: flex;
  justify-content: space-between;
  align-items: flex-start
} 

  .task--info .task__wrap {
    flex-direction: column;
  }

  .test-layout .task__wrap {
    position: static;
  }

.task__info {
  /* width of centre block minus icon and nav. */
  width: calc(100% - 28px - 95px);
  padding-left: 15px;
}

  .test-layout .task__info {
    width: calc(100% - 65px);
  }

.task-list .task__info {
  padding-left: 10px;
  width: calc(100% - 28px - 75px);
}

  .test-layout .task-list .task__info {
    width: calc(100% - 50px);
  }

  .task--info.task--info .task__info {
    width: 100%;
  }

  .task--info .task__info + .task__info {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    margin-top: 6px;
    padding-top: 6px;
  }





.task__type-icon {
  display: block;
  background: #444;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  /* 
  position: absolute;
  top: 5px;
  left: 0;
  */
  /* Flex ordering */
  order: -1  
}

  .task__type-icon {
    position: absolute;
    width: 6px;
    height: 100%;
    left: 0;
    top: 0;
    border: none;
    border-radius: 0;
    transition: width 0.25s;
  }
 
  // .task:hover .task__type-icon {
  //     box-shadow: 0 3px 3px #bbb;
  //     text-decoration: none;
  // }

    .task:hover .task__type-icon {
      text-decoration: none;
      width: 12px;
      box-shadow: 2px 0px 3px rgba(0,0,0,0.1);
    }









  .task-list .task__title {
    // font-size: 14px;
    font-size: 13px;
    line-height: 17px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: 2px;
  }


.widget__title__info {
  // font-size: smaller;
  // float: right;

  // text-transform: none;
  // letter-spacing: normal;
  // font-weight: 300;
  // color: rgba(255,255,255,0.8);
}

.task__duration,
.task__summary,
.task__project {
  padding: 0;
  margin: 0;
}

.task__duration {
  font-size: 11px;
}

.task__summary {
  padding: 0;
  margin: 0 0 5px;
  font-size: 11px;
  line-height: 13px;
}

.task__project {
  padding: 0;
  margin: 0 0 2px 0;
  font-size: 11px;
  line-height: 15px;
}

  .task__info--deadline .task__project {
    margin-top: 2px;
    padding-top: 4px;
  }

  .task__duration,
  .task__project a {
    color: #666;
  }


  .task a {
    font-weight: 400;
  }

  .task__project a {
    font-weight: 300;
  }

  .task__project-add-link {
    white-space: nowrap; 
  }



 

.task-list .tile__lvl-0 .tile__lvl-0-link {
  padding: 2px 2px 0px 2px;
}




.task__url {
  font-size: 11px;
}

.task__url--zoom, 
.task__url--teams { 
  display: inline-block;
  padding: 0 5px;
  border-radius: 3px; 
}  


.task__url--zoom {
  background: #60a7fd;
  color: white;
}

  .task--complete .task__url--zoom {
    background: rgba(0,0,0,0.25);
    color: white;
  }

  .task__url--zoom:hover, 
  .task__url--zoom:active {
    background: #2d8cff;
    color: white;
    text-decoration: none;
  }

.task__url--teams {
  background: #222;
  color: white;
}

  .task--complete .task__url--teams {
    background: rgba(0,0,0,0.25);
    color: white;
  }

  .task__url--teams:hover, 
  .task__url--teams:active {
    background: #444;
    color: white;
    text-decoration: none;
  }





.task-list {
  display: flex;
  flex-direction: column;
  letter-spacing: 0;
}

  .task-list:empty { padding-bottom: 2em; }

.task--reminder {
  /* order: -1; */
  background: #dee;
}

.task.task--info.task--info:not(.task--reminder) {
  /* order: -1; */
  background: #ddd;
}

  .task--reminder + .task--reminder {
    border-top: 1px solid #cdd;
  } 

  .task--info .task__info,
  .task--reminder .task__info {
    width: calc(100% - 75px);
    padding-left: 0;
  }

  .task--info .task__title,
  .task--reminder .task__title {
    margin: 0;
    font-size: 13px;
    // line-height: 1.2em;
    line-height: 17px;
    // padding: 0;

    padding-left: 30px;
    position: relative;
  }

  .task--reminder .task__project {
    display: none;
  }

  .task--reminder .task__type-icon {
    // display: none;
  }

  .task--reminder .reminder-icon {
    color: #368;
    width: 30px;
    line-height: 16px;
    position: absolute;
    left: 0;
  }

  .task--info .reminder-icon {
    width: 30px;
    line-height: 16px;
    position: absolute;
    left: 0;
  }

  .task--reminder .task__duration {
    display: none;  
  }





.task--complete { 
  // background: #ac5;
  background: rgba(170, 204, 85, 0.8);
  color:#333;
}

  .task--complete a {
    color: #333;
  }

  .task--complete.tile--selected,
  .task--complete:hover { 
    // background: #cd7;
    background: rgba(204, 221, 119, 0.8);
    color:#333;
  }






.task.task--onhold {
  opacity: 0.35;
}











/* .task--complete .task__wrap { background: #deb; } */

.tasktype-websites .task__type-icon {
  background: #B8CCE4;
  border-color: #9ac;
}


.tasktype-admin .task__type-icon {
  background: #ffc;
  border-color: #ddb;
}

.tasktype-support-unscheduled .task__type-icon {
  background: #c99;
  border-color: #a77;
}

.tasktype-holiday {
  background: #e6e6e6;
}

  .tasktype-holiday .task__type-icon {
    background: #9c9;
    border-color: #7a7;
  }

.tile.tile--deselected .task__type-icon {
  background-color: white;
  border-color: #666;
}

.tile.tile--selected .task__type-icon {
  background-color: #ac5;
  border-color: #999;
}





.task--otherteam {
  background: #ddd;
  color: #999;
}

  .task--otherteam a {
    color: #999;
  }

  .task--otherteam .task__type-icon {
    background: #bbb;
  }







  .dailytask-list {
    padding-left: 0;
    margin-left: 0;
  }
  
  .dailytask {
    position: relative;
    list-style: none;
    padding-left: 1.5em;
    margin-left: 0;
  }
  
    .dailytask:before {
      position: absolute;
      left: 0;
      top: 3px;   
      color: currentColor;
    }
  
      .dailytask--complete:before {
        content: "\f00c";
        font: normal normal normal 14px/1 FontAwesome;
      }
  
      .dailytask--incomplete:before {
        content: "●";
      }
  
  



.row--deletion {
  display: flex;
  margin: 0;
}

  .row--deletion + .row--deletion {
    border-top: 1px solid #ccc;
  } 

.row--deletion .col {
  flex: 1 1 auto;
  padding: 0.5em 1em;
}

.row--deletion .col + .col {
  border-left: 1px solid #ddd;
}

.row--deletion .col--date {
  flex: 0 0 165px;
}

.row--deletion .col--taskid {
  flex: 0 0 120px;
  text-align: center;
}

.row--deletion .col--name {
  flex: 1 1 auto;
}

.row--deletion .col--user {
  flex: 0 0 150px;
}

.row--deletion .col--options {
  flex: 0 0 80px;
  text-align: right;
}



