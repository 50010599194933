


.project-list {
    display: block;
} 

@media screen and (min-width:992px){

    // .card-body .project-list {
    //     display: grid;
    //     grid-template-columns: 1fr 1fr 1fr 1fr;
    //     column-gap: 1.5em;
    //     row-gap: 1.5em;
    // }

}



