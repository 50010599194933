
.day {
    // width:100%;
    // padding:0 5px
  }
  .day__content {
    background-color:#f6f6f6
  }
  .day__content__action-list {
    display:block;
    text-align:center;
    padding:1em .5em 1em .5em
  }
  .day__content__action {
    display:none;
    position:relative;
    z-index:50;
    margin:0 5px 5px 5px;
    font-size:14px;
    font-weight:700;
    color:#999;
    line-height:2em;
    width:2em;
    height: 2em;
    text-align:center
  }
  .day__content__action-list .day__content__action:not(.day__content__action--secondary) {
    display: inline-flex;
  }
  .day:hover .day__content__action--secondary {
    display: inline-flex;
  }
  .day__title__info--time {
    font-size:11px;
    font-weight:300
  }
  .i--circle {
    border-radius:50%;
    overflow:hidden;
    background:#fff;
    opacity:.8;
    -webkit-box-shadow:0 0 2px #999;
    box-shadow:0 0 2px #999;
    -webkit-transition:.25s all;
    transition:.25s all;

    align-items: center;
    justify-content: center;
  }
  .day:hover .day__content__action,
  .i--circle:hover {
    opacity:1;
    -webkit-box-shadow:0 2px 4px #999;
    box-shadow:0 2px 4px #999;
    text-decoration:none
  }
  .i--circle:focus {
    opacity:1;
    -webkit-box-shadow:0 0 2px #999;
    box-shadow:0 0 2px #999;
    text-decoration:none
  }
  .day-sortable-target .day__title {
    background-color:#ac5;
    color:#000
  }
  .day__title .day__content__action {
    display:none;
    margin:0;
    width:auto;
    text-align:left
  }
  .day:hover .day__title .day__content__action {
    display:block;
    -webkit-box-shadow:none;
    box-shadow:none
  }
  .day:hover .day__title .day__title__info--time,
  .day:hover .day__title .widget__title__info--time {
    display:none
  }
  @media screen and (max-width:1332px) {
    .day {
      -webkit-box-flex:1;
      -ms-flex:1 1 100%;
      flex:1 1 100%
    }
    .day__content:active,
    .day__content:focus {
      display:block
    }
  }
  @media screen and (min-width:1332px) {
    .day {
      float:left
    }
    .week--7-day .day {
      width:calc(calc(100% / 7) - .1px)
    }
    .week--6-day .day {
      width:calc(calc(100% / 6) - .1px)
    }
    .week--5-day .day {
      width:calc(calc(100% / 5) - .1px)
    }
    .week--4-day .day {
      width:calc(calc(100% / 4) - .1px)
    }
    .week--3-day .day {
      width:calc(calc(100% / 3) - .1px)
    }
    .week--2-day .day {
      width:calc(calc(100% / 2) - .1px)
    }
  }
  .day__title__info {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    display:-webkit-inline-box;
    display:-ms-inline-flexbox;
    display:inline-flex;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between;
    font-size:12px;
    line-height:1em
  }
  .day__header-meta .day__title__info--am,
  .day__header-meta .day__title__info--pm {
    -webkit-box-flex:0;
    -ms-flex:0 0 auto;
    flex:0 0 auto
  }
  .day__title__info--meta {
    margin-top:.25rem;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between
  }
  .day__title__info--meta+.day__title__info--meta {
    margin-top:.25rem
  }
  .day__title__info .btn-group {
    margin-left:1em
  }
  .day__title__info .btn {
    padding:.1rem .3rem;
    font-size:inherit;
    line-height:1em;
    border-radius:.15rem;
    color:#fff
  }
  .day__title__info label {
    background-color:rgba(255,255,255,.15)
  }
  .day__title__info label.active,
  .day__title__info label:active,
  .day__title__info label:focus {
    background-color:rgba(255,255,255,.3)
  }
  .day-title {
    font-weight:700;
    font-size:15px;
    line-height:23px
  }
  @media all and (min-width:1561px) {
    .day-title {
      font-weight:700;
      font-size:15px;
      line-height:23px
    }
  }

