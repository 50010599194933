





html, 
body {
	padding: 0;
	margin: 0;
	font-family: 'Roboto', arial, helvetica, sans-serif;
	font-weight: 300;
	letter-spacing: 0.01em;
}

.js body {
	display: none;
}



* {
	font-family: inherit;
	font-style: inherit;
	font-size: inherit;
}


.gwaith {
	font-family: 'Passion One';
	font-weight: 400;
}



.todo {
	outline: 2px dotted #c44;
}



h1, .h1, 
h2, .h2,
h3, .h3,
h4, .h4 {
	font-weight: 300;
	padding: 0;
	margin: 0 0 0.5em 0;
}


p { 
	padding: 0;
	margin: 0 0 1em 0;
}


a {
	font-weight: 600; 
	text-decoration: none;
	color: var(--color-primary);
}

	a:hover {
		text-decoration: underline;
	}
	
	a.btn:hover {
		text-decoration: none;
	}


	h1 a, .h1 a, 
	h2 a, .h2 a, 
	h3 a, .h3 a, 
	h4 a, .h4 a {
		font-weight: 300;
	}

	a i {
		pointer-events: none;
	}


hr { 
	border: none; 
	border-bottom: 1px solid #ddd;
	margin: 0.5em 0 1em 0;
}


ol, 
ul {
	margin-bottom: 2em;
}

	ol > li, 
	ul > li {
		padding: 0;
		margin: 0 0 0.5em 0; 
	}

	li > ul, 
	li > ol {
		margin-top: 0.5em;
		margin-bottom: 1em;
	}





	dl {
		display:block;
		overflow:auto
	}

		 dt {
			display:block;
			float:left;
			width: calc(100% - 210px);
			clear:left;
			overflow-x:hidden;
			white-space:nowrap;
			padding:0 0 0.5em 0;
			margin:0;
			position:relative;
			text-overflow: ellipsis;
		 }
		   
		 dd {
			padding:0 0 0.5em 0;
			margin:0;
			display:block;
			float:left;
			width: 210px;
		}	


.dl--3x {
	display: grid;
	width: 100%;
	grid-template-columns: 2fr 1fr 1fr; 
	column-gap: 1rem;
}
	
	.dl--3x dt,
	.dl--3x dd {
		display: block;  
		margin: 0;
		width: auto;
	}
		  

	  
	  



blockquote { 
  background: #111;
	color: #fff;
	padding: 0.5em 1em;
	margin: 0 0 2em 0;
}

cite { 
	font-size: smaller; 
}

em {
	font-style: italic;
}

mark {
	background-color: rgba( 255, 255, 0, 0.5 );
	display: inline-block;
	padding: 0 1px
}


ul, ol {
	padding-left: 1em;
	margin-left: 0;
}

@media screen and (min-width: 767px){ }

@media screen and (min-width: 959px){ }

@media screen and (min-width: 1279px), print { }





//------------------------------------
//	#1. Global table declarations
//------------------------------------

// @import "partials/base/base--tables";





//------------------------------------
//	#1. Global font declarations
//------------------------------------

// @import "partials/base/base--fonts"; 

span.material-symbols-outlined {
	font-size: inherit;
  	line-height: 1em;
	vertical-align: sub;
	// vertical-align: baseline;
}



.clear,
.bg-clear {
	background: none;
}
button.clear,
button.bg-clear {
	border: none;
}


.subtle,
.subtle a {
	color: rgba( 0, 0, 0, 0.35 );
}




.hide-visually {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	width: 1px !important;
	height: 1px !important;
	line-height: 1px !important; 
	margin-left: -99999px !important;
	overflow: hidden !important;
}

.inline-label {
	padding: 0.25em 0.5em;
	display: inline-block;

	font-weight: 700;
	text-transform: uppercase;
	font-size: 10px;
	line-height: 1em;
	letter-spacing: 0.5px;	  
}




//------------------------------------
//	Include other packages
//------------------------------------

// @import "base/base--messages";

// @import "base/base--themes";

// @import "base/base--forms";

// @import "base/base--icons";

// @import "base/base--tables";
