/* ------------------------------------------------ *\
 * layout--widgets.css
\* ------------------------------------------------ */

// .widget-list,
// .widget,
// .widget__title,
// .widget__content,
// .widget__title__link {
//   display: block;
//   padding: 0;
//   margin: 0;
// }

.widget-list {
}

.popup,
.widget {
  background: #eee;
  // margin-bottom: 20px;
  position: relative;
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.05) inset;
}

.widget__loading-indicator {
  opacity: 0;
  transition: 0.25s opacity;

  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.widget--loading .widget__loading-indicator {
  opacity: 85%;
}

.widget--pale {
  background: #f6f6f6;
}

.popup__title,
.card-header {
  position: relative;
}

.popup__title,
.card-header {
  background: #264653;
  color: white;
}



.card-header .team-member-list .team-member {
  background: #264653;
  color: rgba(255,255,255,0.4);
}

.day--timeover .card-header, 
.day--timeover .team-member-list .team-member  {
  background-color: #B9314F;
  color: white;
}

.day--timeremaining .card-header, 
.day--timeremaining .team-member-list .team-member  {
  background-color: #2A9D8F;
  color: white;
}

.day--notactive .card-header, 
.day--notactive .team-member-list .team-member  {
  background-color: #CCCCCC;
  color: white;
}




.card-header .team-member {
  box-shadow: 0px 0px 4px 4px #264653;
}

.day--timeover .team-member  {
  box-shadow: 0px 0px 4px 4px #B9314F;
}

.day--timeremaining .team-member  {
  box-shadow: 0px 0px 4px 4px #2A9D8F;
}







.popup__title {
  margin-bottom: 0;
}

.ghost-title {
  background: #f0f0f0;
  color: inherit;
  position: relative;
}

/*
.team-list a::after, 
.ghost-title::after,
.widget__title::after
*/
/*
.card-header::after {
  content: "";
  display: block;
  width: 0.5em;
  height: 0.5em;
  line-height: 0.5em;
  position: absolute;
  background: white;
  top: -0.25em;
  left: 1em;
  transform: rotate(45deg);
  border-bottom: 1px solid rgba(0,0,0,.125);
  border-right: 1px solid rgba(0,0,0,.125);
  
}
*/

.popup__title__link,
.widget__title__link {
  display: inline;
  color: rgba(255, 255, 255, 0.8);
  line-height: inherit;
  font-weight: normal;
  text-decoration: none;
}

.popup__title__link + .popup__title__link,
.widget__title__link + .widget__title__link {
  margin-right: 1.5em;
}

.popup__title__link:hover,
.widget__title__link:hover {
  color: white;
}

.popup__content,
.widget__content {
  font-size: 1.4rem;
  line-height: 1.35em;
}

.widget__content.allow-overflow {
  overflow: auto;
  max-height: 720px;
}

.tile__wrap > *:last-child,
.tile__info > *:last-child {
  margin-bottom: 0;
}

@media all and (max-width: 459px) {
  // .popup__content--widthpadding,
  // .ghost-title,
  // .popup__title,
  // .card-header {
  //   padding: 12px 15px;
  // }
}

@media all and (min-width: 460px) and (max-width: 767px) {
  // .popup__content--widthpadding,
  // .widget__title,
  // .ghost-title .popup__title,
  // .card-header {
  //   padding: 13px 16px;
  // }
}

@media all and (min-width: 768px) and (max-width: 1279px) {
  // .popup__content--widthpadding,
  // .popup__title,
  // .widget__title,
  // .ghost-title {
  //   padding: 14px 18px;
  // }

  // .week .card-header {
  //   padding: 15px 15px;
  // }
}

@media all and (min-width: 1280px) {
  // .popup__content--widthpadding,
  // .popup__title,
  // .widget__title,
  // .ghost-title {
  //   padding: 15px 20px;
  // }

  // .week .card-header {
  //   padding: 15px 15px;
  // }
}









/*
Dashboard

ul.team-list
  li.team
    a.team__link
      span.team__details
        span.team__prefix
        span.team__name 
      span.team__meta


*/

.team-list {
  margin: 0;
  padding: 0;
  list-style: none;
  /*
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  */
}

.team {
  display: block;
  position: relative;
  /*
  background: #024;
  background-color: #f6f6f6;
  color: white;
  */
  margin: 0;
  padding: 0;
}



.team__link {
  position: relative;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  width: 100%;

  padding: 15px 20px;
  font-weight: 300;
  color: white;
  color: #444;
}

  .team__details {
    // flex: 1 0 auto;
    font-weight: 500;
  }

    .team-list .team__prefix {
      display: block;
      font-size: smaller;
      text-transform: uppercase;
      font-weight: 300;
    }

    .team-list .team__name {
      display: block;
      word-break: normal;
      white-space: normal;
    }

  .team__meta {
    color: #aaa;
    flex: 0 0 60px;
    text-align: right;
  }




.widget-user-list {
  padding:0;
  margin:0;
  list-style:none
}

.widget-user {
  position:relative;
  padding:5px 0 5px 28px;
  margin:0;
  list-style:none;
  line-height:20px
}

.widget-user__icon {
  display:block;
  background:#444;
  width:20px;
  height:20px;
  border-radius:50%;
  overflow:hidden;
  position:absolute;
  top:5px;
  left:0
}

.widget-user__name {
  font-weight:400
}  







.project__stat {
  display:block;
  font-size:36px;
  line-height:1.1em;
  padding-bottom:1em
}
.project__stat__note {
  display:block;
  font-weight:700;
  opacity:.5
}




