
/* ------------------------------------------------ *\
 * General tile definitions
\* ------------------------------------------------ */

.tile-list,
.tiles,
.tile {
  @include reset;
}

.tile-list .tile {
  margin: 0;
}

.tiles {
}

.tiles--list {
}

.tiles--grid {
  overflow: auto;
}

.tile {
  position: relative;
  z-index: 10;
  background: #f6f6f6;
}

.tile.project .project__title {
  @include inherit-font;
  margin-bottom: 1em;
}

.tiles--grid .tile {
  float: left;
}

.tile--deselected {
  opacity: 0.3;
}

.tile--deselected .task__lvl-0 {
  visibility: hidden;
}

.tile--selected {
  opacity: 1;
}

/* Default hover states
     Overwritten by the task colour/definitions */

.tile:hover {
  background: #eee;
}

.tile--selected:hover {
  background: #eee;
}

.tile-list .tile:not(.task--reminder):not(.task--complete) + .tile {
  border-top: 1px solid #eee;
}

// .tile--list .tile + .tile,
// .tiles--list .tile + .tile {
//   border-top: 1px solid #ddd;
// }

.tile--list .tile:last-child,
.tiles--list .tile:last-child {
  border-bottom: 1px solid #ddd;
}

// @media screen and (max-width: 459px) {
  .tile {
    padding: 12px 15px;
  }

  .task-list .task {
    padding: 10px; 
  }
// }

@media screen and (min-width: 767px) {

  .tile {
    padding: 13px 16px;
  }

  .task-list .task {
    padding: 10px;
  }

}

@media screen and (min-width: 959px) {

  .tile {
    padding: 14px 18px;
  }

  .task-list .task {
    padding: 10px;
  }

}

@media screen and (min-width: 1280px), print {

  .tile {
    padding: 15px 20px;
  }

  .task-list .task {
    padding: 10px;
  }

}

.ui--tile-selector {
  cursor: pointer;
  border: 1px solid #444;
}

.tile--selected .ui--tile-selector {
  background: white;
  border: 1px solid #aaa;
}

.tile__lvl-0,
.tile__lvl-0 .tile__lvl-0-item,
.tile__lvl-0 .tile__lvl-0-link,
.tile__lvl-0 .tile__lvl-1,
.tile__lvl-0 .tile__lvl-1-item,
.tile__lvl-0 .tile__lvl-1-link,
.tile__lvl-0 .tile__lvl-2,
.tile__lvl-0 .tile__lvl-2-item,
.tile__lvl-0 .tile__lvl-2-link {
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
}

.tile__lvl-0 {
  width: 90px;
  z-index: 250;
  // font-size: 14px;
  // line-height: 1.5em;
  display: flex;
  justify-content: flex-end;
}

.task-list .tile__lvl-0 {
  width: 75px;
}

  .test-layout .task-list .tile__lvl-0 {
    width: 60px;
  }

.card-header .tile__lvl-0 {
  /*
    top: 25%;
    right: 1em;
    */
}

.tile__lvl-0 .tile__lvl-0-item {
  /* float: left; */
  display: inline-flex;
  position: relative;
}

.tile__lvl-0 .tile__lvl-0-item:hover .tile__lvl-0-link {
  background: #555;
  color: #fff;
}

.tile__lvl-0 .tile__lvl-0-item + .tile__lvl-0-item {
  margin-left: 5px;
}

.tile__lvl-0 .tile__lvl-0-link {
  // padding: 5px;
  padding: 0;
}

.card-header .tile__lvl-0-link {
  color: #eee;
}

.card-header .tile__lvl-0-link i {
  pointer-events: none;
}

.tile__lvl-0 .tile__lvl-1 {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 250;

  /* width: 125px; */
  min-width: 125px;
  background: #444;
  color: #fff;

  font-size: 13px;
}

.col-lg-4:nth-child(3n) .tile__lvl-0 .tile__lvl-1,
.col-lg-3:nth-child(4n) .tile__lvl-0 .tile__lvl-1 {
  left: auto;
  right: 0;
}

.col-lg-4:nth-child(3n) .tile__lvl-0 .tile__lvl-2,
.col-lg-3:nth-child(4n) .tile__lvl-0 .tile__lvl-2 {
  left: auto;
  right: 100%;
}

@media screen and (max-width: 1004px) {
  .day .tile__lvl-0 .tile__lvl-1 {
    left: auto;
    right: 0;
  }

  .day .tile__lvl-0 .tile__lvl-2 {
    left: auto;
    right: 100%;
  }
}

@media screen and (min-width: 1005px) {
  .day:nth-child(5) .tile__lvl-0 .tile__lvl-1 {
    left: auto;
    right: 0;
  }

  .day:nth-child(5) .tile__lvl-0 .tile__lvl-2 {
    left: auto;
    right: 100%;
  }
}

.tile__lvl-0 .tile__lvl-0-item:hover .tile__lvl-1 {
  display: block;
}

.tile__lvl-0 .tile__lvl-0-item {
  position: relative;
  color: #fff;
}

.tile__lvl-0 .tile__lvl-1-item,
.tile__lvlv1 .tile__lvl-1-item {
  position: relative;
}

.tile__lvl-0 .tile__lvl-1-link {
  // padding: 4px 15px 6px 15px;
  // padding: 0.5rem 1rem 0.5rem 1rem;
  padding: 0.5em 1em 0.5em 1em;
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
}

.tile__lvl-0 .tile__lvl-1-item:hover .tile__lvl-1-link,
.tile__lvl-0 .tile__lvl-1-link:hover {
  background: #555;
}

.tile__lvl-0 .tile__lvl-2 {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 150px;
  width: auto;
  z-index: 500;
  background: #555;
}

.tile__lvl-0 .tile__lvl-1-item:hover .tile__lvl-2 {
  display: block;
}

.tile__lvl-0 .tile__lvl-2-item {
  color: white;
}

.tile__lvl-0 .tile__lvl-2-link {
  // padding: 4px 15px 6px 15px;
  // padding: 0.5rem 1rem 0.5rem 1rem;
  padding: 0.5em 1em 0.5em 1em;
  color: white;
  text-decoration: none;
  white-space: nowrap;
}

.tile__lvl-0 .tile__lvl-2-item.disabled {
  color: #999;
  font-style: italic;
}

.tile__lvl-0 .tile__lvl-2-item:not(.disabled) .tile__lvl-2-link:hover {
  background: #666;
}

@keyframes taskintro {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.tile__wrap {
  opacity: 0;
  animation: taskintro 0.25s ease-in 1 forwards;
  animation-delay: 1s;
}

@for $i from 1 through 9 {
  .tile:nth-of-type(#{$i}) .tile__wrap {
    animation-delay: calc(#{$i} * 0.05s);
  }
}
