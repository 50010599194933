

@font-face {
    font-family: 'Passion one';
    src: local('Passion one'),
      url('../fonts/Passion_One/PassionOne-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Passion one';
    src: local('Passion one'),
      url('../fonts/Passion_One/PassionOne-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Passion one';
    src: local('Passion one'),
      url('../fonts/Passion_One/PassionOne-Black.ttf') format('truetype');
    font-weight: 900;
}

