

/* ------------------------------------------------ *\
 * 
 * Nav.css
 * 
 * 0. Reset styles
 * 1. First level navigation
 * 2. Second level navigation
 * 3. Third level navigation
 * 
\* ------------------------------------------------ */


.header {
	position: sticky;
	top: 0;
	z-index: 1000;
}



/*

.nav, 
.dropdown-menu,
.dropdown-submenu {
	// font-size: 1.3rem;
	// text-transform: uppercase;
}



.nav, 
.nav a {
	color: #fff;

}

*/




/* ------------------------------------------------ *\
 * 0. Reset styles
\* ------------------------------------------------ */

/*
.nav {
	display: block;
	padding: 0;
	margin: 0;
	list-style: none;
	border: none;
	background: none;
	
}
*/






/* 
.container--footer {
	padding-top: 10px
}
*/





// 2023
.withcontentseparator {
	border-bottom: 2px solid #000;
	border-bottom: 2px dotted rgba(0,0,0,.25);
	margin-bottom: 1.5em
}

.withcontentseparator h1 {
	margin-bottom: .25em
}

@media screen and (min-width:767px) {
	.withcontentseparator h1 {
		margin-bottom: .25em
	}
}




/*
.container--branding {
	overflow: visible;
	padding-left: 0;
	padding-right: 0;
}
*/


// 2023
.navbar-brand {
	padding: 0;
	// flex: 0 0 auto;
}



	// .navbar-brand .logo {
	// 	padding: 0 0 0.2em 0;
	// }


// 2023

.navbar-nav a,
.navbar-expand .navbar-nav .nav-link { 
	color: rgba(255, 255, 255, 0.75);
	// text-transform: uppercase;
	font-weight: 400;
	letter-spacing: 0.25px;

	border: 0;
	padding: 0.45em 1em;
	// padding-left: 1em;
	// padding-right: 1em;
}

.navbar-expand-lg .navbar-nav .nav-link {
	padding: 0.45em 1em;
}


	.dropdown-item:focus, 
	.dropdown-item:hover,
	.nav-link:focus-visible,
	.nav-link:focus,
	.nav-link:active,
	.nav-link:hover {
		color: rgba(255, 255, 255, 1.00);
		box-shadow: none;
		text-decoration: none;
		background-color: #555;
		outline: none;
	}

	.navbar-nav .nav-link.active, 
	.navbar-nav .nav-link.show {
		color: rgba(255, 255, 255, 0.75);
	}






// 2023
.dropdown-toggle {
	display: block;
}

.dropdown-toggle::after {
	margin-left: 0.5em;
}

.navbar-nav > .dropdown.show > .dropdown-toggle {
	background: #000;
}

// 2023
.dropdown.show {
	background-color: #222;
}

.dropdown-menu {
	margin-top: 0;
}

// 2023
.dropdown.show .dropdown-menu .dropdown-menu {
	background-color: #222;
	left: 100%;
	top: 0;
}









.dropdown-menu .dropdown-item {
	padding-top: 0.75em;
}

.navbar-notifications .dropdown-item {
	line-height: 1.4em;
}












.nav-toggle {
	display: block;
	position: absolute; 
	top: 50%;
	transform: translateY(-50%);
	right: 20px;
	color: white;
	text-decoration: none;
}

.nav-toggle {
	display: inline-block;
}

@media screen and (min-width: 959px){
	
	.nav-toggle {
		display: none;
	}
	
}











/* ------------------------------------------------ *\
 * 1. First level navigation 
\* ------------------------------------------------ */

.navbar-inverse {
	font-size: 13px;
}

// .navbar-inverse .navbar-nav > li
// .navbar-inverse .navbar-nav > li:hover {
// 	background: none;
// }

// .navbar-inverse .navbar-nav > li > a, 
// .navbar-inverse .navbar-nav > li > a {
// 	color: #ccc;
// 	font-weight: 400;
// 	letter-spacing: 0.25px;
// }

// .navbar-inverse .navbar-nav > li > a:focus, 
// .navbar-inverse .navbar-nav > li > a:hover {
// 	color: #fff;
// 	text-decoration: none;
// }





// .nav-item.dropdown:hover,
// .dropdown-toggle.show {
// 	background: black;
// }

.dropdown-menu[data-bs-popper] {
	margin-top: 0;
}



.dropdown-menu {
	font-size: inherit;
	border: none;
	padding-top: 0;
	padding-bottom: 0;
	background: black;
	border-radius: 0;
}




/* ------------------------------------------------ *\
 * 2. Second level navigation 
\* ------------------------------------------------ */




.dropdown a:hover {
	color: #fff;
	background: #555;
	// font-weight: 300;
	text-decoration: none;
}

	.dropdown .dropdown a:hover {
		color: #fff;
		background: #777;
		// font-weight: 300;
		text-decoration: none;
	}








/* ------------------------------------------------ *\
 * 4. 
\* ------------------------------------------------ */






.debugging {
	border-top: 1px dashed #999;
	padding: 0;
	margin: 5px 0 0 0;
}




/* ------------------------------------------------ *\
 * 5. 
\* ------------------------------------------------ */


.nav-calendar,
.nav-header-lvl-0,
.nav-header-lvl-0__item,
.nav-header-lvl-0__link {
	display: inline-block;
	margin: 0;
	list-style: none;
	border: none;
	background: none;	
}


.nav-calendar a {
	position: relative;
}


/* 
@media screen and (max-width: 459px){ 

	.nav-calendar a,
	.nav-header-lvl-0 {

	}

}

@media screen and (min-width: 760px) and (max-width: 767px){ 

	.nav-calendar a,
	.nav-header-lvl-0 {

	}

}

@media screen and (min-width: 768px) and (max-width: 1279px){

	.nav-calendar a,
	.nav-header-lvl-0,
	.nav-header-lvl-0__link {
		// margin-left: 2em;
	}

}

@media screen and (min-width: 1280px), print { 

	.nav-calendar a,
	.nav-header-lvl-0,
	.nav-header-lvl-0__link {
		// margin-left: 2em;
	}

}


 */

 








/* 

.tabs { 
  position: relative; 
  width: 100%; 
}

.tab {
	width: 100%;
	border-bottom: 2px solid #333;
	margin-bottom: 1em;
	padding-top: 1em;
}

.tab img { max-width: 100%; height: auto; }

.tab-nav,
.tab-nav__item,
.tab-nav__item__link { 
  padding: 0;
  margin: 0;  
  list-style: none; 
}

.tab-nav { 
  display: block; 
  width: 100%; 
  overflow: auto; 
  background: white;
  border-bottom: 2px solid #333;
}

  .tab-nav__item { 
    display: block; 
    float: left; 
    margin: 0; 
    padding: 0;
  }

    .tab-nav__item + .tab-nav__item {
      margin-left: 2px;
    }

    .tab-nav__item__link {
      display: block;
      padding: 0.5em 0.75em; 
      background: #aaa;
      color: white;
      height: auto; 
      text-align: center;
      border: none; 
			text-decoration: none;
    }

      .tab-nav .tab-selected .tab-nav__item__link {
        background: #444;
        color: #eee;
        text-decoration: none;
        position: relative;
        z-index: 500;
      }

      .tab-nav .tab-nav__item__link:hover,
      .tab-nav .tab-nav__item__link:focus {
        background: #777;
        color: #eee;
        text-decoration: none;
        position: relative;
        z-index: 500;
      }


 */










 

.navbar-form {
	
}

.navbar-form .form-group {
	position: relative;
	z-index: 100;
}

.navbar-form input[type=text] {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.navbar-form button { 
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
	border: none;
	font-size: 12px;
	line-height: 29px;
	background-color: #ccc;
	color: #444;
	padding-left: 4em;
}




.qs-results {
	position: absolute;
	width: 556px;
	// display: flex;
	background: linear-gradient(rgba(0,34,68,1) 0,rgba(0,34,68,.95) 60%,#024 100%);
	z-index: 50; // position under searchbox 
	top: 0;
	right: -12px;
	padding: 5em 0 0 0;
	box-shadow: 0 3px 4px rgba(255,255,255,.1);
	height: 100vh;
}

	.qs-results__close {
		position: absolute; 
		right: 1em;
		top: 1em;
		line-height: 1.4em;
		display: inline-flex;
		align-items: center;
		text-transform: none;
		text-decoration: none;
		opacity: 0.5;
	}

		.qs-results__close:hover,
		.qs-results__close:active {
			text-transform: none;
			text-decoration: none;
			opacity: 1;
		}


	// .qs-results__group {
	// 	flex: 1 1 50%;
	// 	padding: 5px 10px;
	// }

	// .qs-results__group:empty {
	// 	padding: 0;
	// }

	// .qs-results__group + .qs-results__group { margin-left: 2em; }




.qs-result-heading {
	font-weight: 700;
	padding: 5px 10px;
	margin: 0;
}

.qs-result-list { 
	display: block;
	padding: 0; 
	margin: 0 0 1em 0;
}

	.qs-result {
		display: block;
		font-size: 13px;
		line-height: 17px;
		padding: 0; 
		margin: 0;
	}

	.qs-result + .qs-result { 
		// border-top: 1px solid rgba(255,255,255,0.25);
	}

  .qs-result__link {
    display: block;
    padding: 0.5em 0.75em;
    color: white;
	font-weight: 300;
  }

  .qs-result__link:hover {
    color: white;
    background-color:#024;
	background-color: #264653;
    text-decoration: none;
  }

  .qs-result__info {
    display: block;
	font-size: 10px;
	opacity: 0.5;
  }

	// .qs-result--complete .qs-result__info {
	// 	margin-left: 2em;
	// }



// @supports(display:grid){


// 	.qs-results {
// 		display: grid;
// 		grid-template-columns: 1fr 1fr;
// 		grid-template-rows: minmax(0px, min-content); 
// 	}
		
// 		.qs-results-users {
// 			grid-column-start: 1;
// 			grid-row-start: 1;
// 		}
		
// 		.qs-results-projects {
// 			grid-column-start: 1;
// 			grid-row-start: 2;
// 		}
		
// 		.qs-results-tasks {
// 			grid-column-start: 2;
// 			grid-row-start: 1;
// 			grid-row-end: span 2;
// 		}

// 		.qs-results__group + .qs-results__group { margin-left: 0em; }

// }
	














.navbar-notifications {

	.nav-item {
		padding-top: 0;
		padding-bottom: 0;
		display: inline-flex;
		align-items: center;
		align-content: center;
	}
	
	.dropdown-toggle.dropdown-toggle {
		// padding-top: 0.2em;
		// padding-bottom: 0.2em;
		font-size: 18px;
		line-height: 18px;

		display: flex;
		align-items: center;
		
	}

	.dropdown-menu {
		
		width: 320px;
		max-height: 90vh;
		overflow-y: auto;
	}

	.dropdown-item {
		white-space: normal;
	}

}


.notifications-list {
	min-width: 275px;
}

.notification { 
	padding: 6px 12px 7px 12px;
	line-height: 1.4em;
	font-size: 13px;
}

	.notification__dt {
		display: block;
		font-size: smaller;
	}

  .notification a { color: white; }




.dropdown--notifications > a.open {
	background: linear-gradient(rgba(0,34,68,.9) 0,rgba(0,34,68,.95) 60%,#024 100%);
}

.dropdown-menu--notifications {
	 width: 300px;
	 left: 0 !important;
	 right: auto !important;
	 background: linear-gradient(rgba(0,34,68,.9) 0,rgba(0,34,68,.95) 60%,#024 100%);
	 z-index: 1000;
	 padding: 0;
	 box-shadow: 0 3px 4px rgba(255,255,255,.1);
}


.dropdown--notifications .dropdown-toggle.show,
.dropdown-menu--notifications {
	background: #024;
	background: #264653;
}


 .notification-list { 
	display: block;
	padding: 0.5em 0; 
	margin: 0 0 1em 0;
}

	.notification {
		display: block;
		font-size: 13px;
		line-height: 17px;
		padding: 0; 
		margin: 0;
	}

	.notification:nth-child(n+8){
		display: none;
	}

	.notification + .notification { 
		// border-top: 1px solid rgba(255,255,255,0.25);
	}

  .notification__link {
    display: block;
    padding: 0.5em 1em;
	color: white;
	white-space: normal !important;
	text-transform: none;
	font-weight: 300;
	
  }

  .notification__link:hover {
    color: white;
    background-color:#024;
	background-color: #264653;
    text-decoration: none;
  }

  .notification__info {
    display: block;
	font-size: 10px;
	opacity: 0.5;
	padding-top: 2px;
  }












.navbar-right .dropdown-menu {
  left: auto;
  right: 0;
}

.navbar-expand .navbar-collapse {
	display: none;
}













.navbar .navbar-toggler {
    white-space: nowrap;
    color: white;
	border: none;
}

	.navbar .navbar-toggler:active,
	.navbar .navbar-toggler:focus {
		border: none;
		box-shadow: none;
		outline: 1px dotted rgba(255, 255, 255, 0.5);
	}

@media screen and (min-width: 719px){

	.navbar-expand .navbar-toggler {
		display: none;
	}

	.navbar-expand .navbar-collapse {
		display: flex;
		flex-basis: auto;
	}

}










.main-navbar {
	flex-grow: 1;
}

@media screen and (max-width: 1199px) {
	
	.main-navbar {

		width: auto;
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		min-height: 100vh;
		
		background: #000;
		
		z-index: 1000;
		padding-bottom: 10em;
		padding-top: 0.75em;

		

		// overflow-y: auto;
	}

	.main-navbar .nav {
		display: block;
		margin-right: 75px;
	} 

		.main-navbar .navbar-feedback {
			display: none;
		}
		
		// .main-navbar .navbar-notifications {
		// 	display: none;
		// }

		.main-navbar .navbar-profile {

		}

		.main-navbar .navbar-form {
			display: flex;
			margin-bottom: 1em;
		}
		
			.main-navbar .navbar-form input[type=text] { 
				border-top-right-radius: 0;
				border-bottom-right-radius: 0px; 
			}
			
			.main-navbar .navbar-form button { 
				border-top-left-radius: 0;
				border-bottom-left-radius: 0px; 
				background-color: #444;
				color: #ddd; 
			}


	.main-navbar .navbar-profile {
		display: block;
	}








	.main-navbar .dropdown-menu.show {
		position: relative;
		display: block;
		padding-left: 2em;
	}

		// .main-navbar .dropdown-submenu.show {
		// 	position: relative;
		// 	display: block;
		// 	padding-left: 2em;
		// }




		.main-navbar .dropdown-submenu { 
			display: block; 
			position: relative;
			left: auto;
			top: auto;
			// margin-left: 2em;
			margin-bottom: 1em;
			width: auto;

			padding-left: 1em;
		  }

			.main-navbar .dropdown-toggle::after {
				display: none;
			}
	
	// .main-navbar .navbar-form {
	// 	display: block;
	// }


}















.tab-pane.active {
	/* match highlighted tab bg colour */ 
	background: white;
}


