

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'),
      url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'),
      url('../fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'),
      url('../fonts/Roboto/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
}

