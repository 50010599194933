
.card-header {

    // align-items: center;
    // padding: 0.75rem 1rem 0.5rem 1rem;
    padding: 0.75rem 1rem 0.75rem 1rem;

    line-height: 1.25em;

    &::after {
        content: "";
        display: block;
        width: 0.5em;
        height: 0.5em;
        line-height: 0.5em;
        position: absolute;
        background: white;
        top: -0.25em;
        left: 1em;
        transform: rotate(45deg);
        border-bottom: 1px solid rgba(0,0,0,.125);
        border-right: 1px solid rgba(0,0,0,.125);
    }

    a {
        color: white;
    }

    &__main a {
        // color: rgba(255,255,255,.8);
        font-weight: 400;
    }

    &__alt {
        padding-left: 0;
    }

    &__alt a {
        // color: rgba(255,255,255,.8);
        font-weight: 300;
    }

    &:not(.ghost-title) a {
        // color: rgba(255,255,255,.8);
        /* font-weight: 400; */
    }

    & .body,
    & .body-reset {
        line-height: 1.25em;
    }

}



