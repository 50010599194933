
.message-container {
    margin: 0;
    padding: 0;
    list-style: none;

    position: fixed;
    bottom: 0;
    right: 1em;
    z-index: 500;

    width: 300px;
    max-height: 75%;
    overflow-y: auto;
}

.message-list {
    margin: 0 0 1em 0;
    padding: 0;
    list-style: none;
}

.message {
    margin: 0;
    padding: 0;
    list-style: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
}

    .message + .message {
        margin-top: 0.5em;
    }

.message__title {

} 

.message__content {
    
} 

